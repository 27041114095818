<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <section class="container">
      <agent />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const Agent = () => import('@/components/search-agent/agent');

import { mapState } from 'vuex';
export default {
  async fetch({ store, route, query }) {
    console.log(
      'Panggil get search agent dari fetch di search-agent.vue dengan isi route: ',
      route,
    );

    store.commit('global/SET_LOADING', true);
    store.commit('v2/search/SET_AGENTS', []);

    await store.dispatch('v2/search/getAgents', {
      query: query,
      params: route.params,
    });
    store.commit('global/SET_LOADING', false);

    const baseUrl = store.state.global.baseUrl;
    let title = 'Searching for all agents at BeliRumah.';
    // if (title.length > 50) title = title.substring(0, 47) + '...';
    title += ' | BeliRumah';

    let description = 'Searching for all agents registered with BeliRumah.';
    if (description.length > 300) description = description.substring(0, 300) + '...';
    description += ' | BeliRumah.co';

    const head = {
      title: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  beforeDestroy() {
    this.$store.commit('v2/search/SET_SEARCH_TERM', '');
  },
  components: {
    Agent,
    DefaultLayout,
  },
  computed: {
    ...mapState({
      agents: (state) => state.v2.search.agents,
      meta: (state) => state.v2.search.metaAgent,
      isLoading: (state) => state.global.isLoading,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.searchAgent'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
};
</script>
